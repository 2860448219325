import { Button, Card, Image, Spin, Typography } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { GarmentSize, Look, Model, OptionInterface, Size } from '../types/api-types'
import { resizeImage } from '../utils/image'
import { useAppSelector } from '../services/store/store'
import { useDispatch } from 'react-redux'
import { changeImageIndex } from '../services/store/slice/lookSlice'
import { changeModalVisible } from '../services/store/slice/configSlice'
import { trackEvent } from '../utils/tracking'
import { getGarmentSizeLabel, getModelMatchingGarmentSize } from '../shared_utils/garment-size'
import { changeGarmentSizeIndex } from '../services/store/slice/modelSlice'
import { addGarmentSizeToCart } from '../shared_utils/addtocart'
import useCustomTranslation from '../shared_utils/translation'
import FilterSelect from './filter'
import { useGetLookQuery } from '../services/api/api-look'
import MultiSize from './MultiSize'
import { ZoomInOutlined } from '@ant-design/icons'
import { getSyncIcon } from '../utils/icon'
import { MODAL_ROOT_ID } from '../utils/constant'
import { formattedHeight } from '../utils/height'

const { Title, Paragraph } = Typography

const LOADER = (
    <>
        <div className='imagesmooth--loader-bg' />
        <div className='imagesmooth--loader'>
            <Spin size='large' />
        </div>
    </>
)

interface CardModelProps {
    model: Model
    look: Look
    itemType: string
    ratio?: number
    enablePreview?: boolean
}

export default function CardModel(props: CardModelProps) {
    const { model, look, itemType, enablePreview } = props

    const dispatch = useDispatch()
    const { t } = useCustomTranslation()

    const lookImageIndex = useAppSelector((state) => state.look.imageIndex)
    const modelGarmentSizeIndex = useAppSelector((state) => state.model.garmentSizeIndex)
    const config = useAppSelector((state) => state.config.apiConfig)

    const [sizeValue, setSizeValue] = useState<string>(
        getModelMatchingGarmentSize(model.model_garment_sizes || [], look[itemType].product_sizes)
            ?.value || ''
    )

    const [selectedMultisize, setSelectedMultisize] = useState<Size | undefined>()
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)

    // ---- Look built to get the multisize image ----
    const lookRequest: Look = useMemo(() => {
        return {
            ...look,
            model: model,
            ...(selectedMultisize && model.look_multisizes
                ? {
                      multisizing: {
                          [model.look_multisizes[itemType].garment_id]:
                              selectedMultisize.label || '',
                      },
                  }
                : null),
        }
    }, [look, model, selectedMultisize, itemType])

    const { data: lookMultisize, isFetching: isLookLoading } = useGetLookQuery(lookRequest, {
        skip: !model.look_multisizes || !selectedMultisize,
    })

    const garmentSizeFromModel = useMemo(() => {
        if (model && model.model_garment_sizes) {
            return getModelMatchingGarmentSize(
                model.model_garment_sizes,
                look[itemType].product_sizes
            )
        }
    }, [model, itemType, look])

    const modelImage = useMemo(() => {
        // ---- If we have the multisize look available we use it's images ----
        if (
            lookMultisize &&
            lookMultisize.image_urls &&
            lookMultisize.image_urls.length >= lookImageIndex
        ) {
            return lookMultisize.image_urls[lookImageIndex]
        }

        // ---- If we have the model images we use them ----
        if (model.image_urls && model.image_urls.length >= lookImageIndex) {
            return model.image_urls[lookImageIndex]
        }

        // ---- Default model image ----
        return model.image_url
    }, [lookImageIndex, model, lookMultisize])

    const modelDescription = useMemo(() => {
        if (!model) {
            return ''
        }

        let modelGarmentSize = model.model_garment_sizes
            ? garmentSizeFromModel?.label
            : model.model_garment_size

        if (!modelGarmentSize) {
            if (model.model_garment_sizes) {
                modelGarmentSize = model.model_garment_sizes[modelGarmentSizeIndex || 0]
            }
        } else {
            const foundIndex = model.model_garment_sizes?.findIndex(
                (size) => size === modelGarmentSize
            )
            dispatch(changeGarmentSizeIndex(foundIndex === undefined ? null : foundIndex))
        }

        return (
            model.model_description ||
            t('model_description', {
                modelName: model.model_display || model.model_name,
                modelHeight: formattedHeight(model.model_height, config?.height_format),
                modelSize: modelGarmentSize,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, garmentSizeFromModel])

    const selectOptions = useMemo(() => {
        if (!look[itemType]?.product_sizes) {
            return []
        }

        const baseOptions: OptionInterface[] = look[itemType].product_sizes.map(
            (size: GarmentSize) => {
                return {
                    label: size.label,
                    value: size.value,
                    extra:
                        size.value === garmentSizeFromModel?.value
                            ? t('worn_by', { modelName: model.model_display || model.model_name })
                            : '',
                }
            }
        )

        return baseOptions
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [look, itemType, model, garmentSizeFromModel])

    const previewValue = useMemo(() => {
        return enablePreview
            ? {
                  visible: previewVisible,
                  onVisibleChange: () => setPreviewVisible(false),
                  src: modelImage,
                  mask: null,
                  toolbarRender: () => null,
                  getContainer: `#${MODAL_ROOT_ID}`,
              }
            : false
    }, [enablePreview, modelImage, previewVisible])

    const handleSizeChange = (newSize: string) => {
        if (newSize) {
            trackEvent(
                'Size Selected',
                [
                    look[itemType],
                    model,
                    {
                        item_size_selected: newSize,
                        item_size_selected_label: getGarmentSizeLabel(
                            newSize,
                            look[itemType].product_sizes
                        ),
                        old_item_size_selected: sizeValue,
                        old_item_size_selected_label: getGarmentSizeLabel(
                            sizeValue,
                            look[itemType].product_sizes
                        ),
                    },
                ],
                'Card'
            )
        }

        // ---- Check on every selectContainerElements if we have the placeholder and remove it if it exists ----
        const selectContainerElements = document.getElementsByClassName(
            `cardModelSizeSelect-${model.model_id}`
        )
        for (let i = 0; i < selectContainerElements.length; i++) {
            const elements =
                selectContainerElements[i].getElementsByClassName('ant-select-selector')

            if (elements.length > 0) {
                const selectElement = elements[0]

                // ---- Remove unavailable placeholder if it exists ----
                const unavailableElement = selectElement.getElementsByClassName(
                    'select--unavailable-placeholder'
                )[0]
                if (unavailableElement) {
                    unavailableElement.remove()
                }
            }
        }

        setSizeValue(newSize)
    }

    const handleMultisizeChange = (newSize: Size) => {
        // ---- Tracking ----
        trackEvent(
            'MultiSize Selected',
            [
                look[itemType],
                model,
                {
                    item_size_selected: newSize.value,
                    item_size_selected_label: newSize.label,
                    old_item_size_selected: selectedMultisize?.value,
                    old_item_size_selected_label: selectedMultisize?.label,
                },
            ],
            'Card'
        )

        // ---- State update ----
        setSelectedMultisize(newSize)
    }

    const handleAddToCart = () => {
        // ---- We add the multisize value if it exist, if not we take the sizeValue ----
        const localSizeValue = selectedMultisize?.value || sizeValue

        // ---- Tracking ----
        trackEvent(
            'Item Added to cart',
            [
                look[itemType],
                model,
                {
                    item_size_selected: localSizeValue,
                    item_size_selected_label: getGarmentSizeLabel(
                        localSizeValue,
                        look[itemType].product_sizes
                    ),
                },
            ],
            'Card'
        )

        // ---- Add to cart method ----
        addGarmentSizeToCart(look[itemType], localSizeValue, (success: boolean) => {
            if (success) {
                dispatch(changeModalVisible(false))
            } else {
                alert('There was a problem while adding to cart')
            }
        })
    }

    const handleChangeImage = () => {
        trackEvent(
            'Back Changed',
            [
                look[itemType],
                model,
                { back_value: lookImageIndex === 1 ? 'on' : 'off', back_type: 'icon' },
            ],
            'Card'
        )
        dispatch(changeImageIndex(lookImageIndex === 1 ? 0 : 1))
    }

    const handleZoomImage = () => {
        trackEvent(
            'Zoom Opened',
            [
                look[itemType],
                model,
                { back_value: lookImageIndex === 1 ? 'on' : 'off', zoom_type: 'icon' },
            ],
            'Card'
        )

        setPreviewVisible(true)
    }

    useEffect(() => {
        if (!selectOptions) {
            return undefined
        }

        let intervalId = 0
        // ---- Add a custom placeholder if the modelSize is not available ----
        if (
            modelGarmentSizeIndex !== null &&
            model.model_garment_sizes &&
            model.model_garment_sizes[modelGarmentSizeIndex] &&
            selectOptions.length !== 0 &&
            selectOptions.findIndex(
                (option) =>
                    option.label === (model.model_garment_sizes as string[])[modelGarmentSizeIndex]
            ) === -1
        ) {
            // ---- We need the interval in case the selectContainer is not yet loaded ----
            intervalId = window.setInterval(() => {
                const selectContainerElements = document.getElementsByClassName(
                    `cardModelSizeSelect-${model.model_id}`
                )

                // ---- If the list is empty we wait for next interval ----
                if (selectContainerElements.length === 0) {
                    return
                }

                clearInterval(intervalId)

                // ---- Add the element in every selectContainer as there can be multiple because of carousel infinite ----
                for (let i = 0; i < selectContainerElements.length; i++) {
                    const selectElement =
                        selectContainerElements[i].getElementsByClassName('ant-select-selector')[0]
                    if (
                        selectElement?.getElementsByClassName('select--unavailable-placeholder')
                            .length === 0 &&
                        model.model_garment_sizes
                    ) {
                        const sizeSpan = document.createElement('span')
                        sizeSpan.textContent = model.model_garment_sizes[modelGarmentSizeIndex]
                        sizeSpan.className = 'select--unavailable-placeholder'
                        selectElement?.prepend(sizeSpan)
                    }
                }
            }, 100)
        }
        return () => {
            clearInterval(intervalId)
        }
    }, [selectOptions, model, modelGarmentSizeIndex])

    return (
        <Card
            className='card-carousel card-carousel--container override_card_container'
            hoverable
            cover={
                <div className='card-carousel--image-container'>
                    <Image
                        src={
                            resizeImage(modelImage, { width: 800 }) +
                            (config?.look_image_ratio
                                ? `&cratio=${config?.look_image_ratio}`
                                : '') +
                            `&expand=1x2`
                        }
                        preview={previewValue}
                        placeholder={LOADER}
                        draggable={false}
                    />
                    {isLookLoading && LOADER}
                    {enablePreview && (
                        <div className='card-carousel--look-option'>
                            <div className='card-carousel--look-option-container'>
                                <Button
                                    className='button--icon button--look-zoom'
                                    icon={<ZoomInOutlined style={{ fontSize: 20 }} />}
                                    onClick={handleZoomImage}
                                />
                                {model.image_urls && model.image_urls.length > 1 && (
                                    <Button
                                        className='button--icon'
                                        icon={getSyncIcon()}
                                        onClick={handleChangeImage}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            }
        >
            {model.look_multisizes && model.look_multisizes[itemType] ? (
                <MultiSize
                    model={model}
                    modelDescription={modelDescription.replace(
                        model.model_display || model.model_name,
                        ''
                    )}
                    itemType={itemType}
                    onAddToCart={handleAddToCart}
                    onValueChange={handleMultisizeChange}
                />
            ) : (
                <>
                    <div className='card-carousel--body-container'>
                        <Title
                            ellipsis={{
                                rows: 1,
                            }}
                            className='card-carousel--title override_card_carousel_title'
                        >
                            {model.model_display || model.model_name}
                        </Title>
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                                tooltip: true,
                            }}
                            className='card-carousel--text'
                        >
                            {modelDescription}
                        </Paragraph>
                    </div>

                    <div
                        className={`card-carousel--cta-container cardModelSizeSelect-${model.model_id}`}
                    >
                        <FilterSelect
                            name='size'
                            placeholder={t('size_not_available')}
                            options={selectOptions}
                            customStyle={{ width: '100%' }}
                            onChange={handleSizeChange}
                            value={sizeValue}
                        />

                        {selectOptions.length === 0 ||
                        !selectOptions.find((option) => option.value === sizeValue) ? (
                            <Button type='primary' style={{ width: '100%' }} disabled={true}>
                                {t('not_available')}
                            </Button>
                        ) : (
                            <Button
                                type='primary'
                                style={{ width: '100%' }}
                                onClick={handleAddToCart}
                            >
                                {t('add_to_cart')}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </Card>
    )
}
